
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Button, NavBar, Grid, GridItem, Icon } from "vant";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Icon.name]: Icon,
  },
})
export default class More extends Mixins(Mixin) {
  columnCount = 0; // 每行展示多少项
  groups: Array<{
    groupName: string; // 组名称
    groupItems: Array<cms.ColumnDetail>; // 组数据项
  }> = []; // 分组数据

  mounted(): void {
    this.updateData();
  }

  /**
   * 更新金刚区更多页面数据
   */
  updateData(): void {
    this.$api.cmsApi.commonInfo.vajraDistricts("TICKET", ({ data }) => {
      let columnCount = data.columnCount || 0;
      if (columnCount > 0) {
        this.columnCount = columnCount;
        let vajraDistricts = data.vajraDistricts || [];
        if (vajraDistricts.length > 0) {
          let groupNameDataMap: Record<string, Array<cms.ColumnDetail>> = {};
          vajraDistricts.forEach((vajraDistrict) => {
            if (vajraDistrict.columnType != 4) {
              let columnTypeName = vajraDistrict.columnTypeName || "";
              let groupItems = groupNameDataMap[columnTypeName];
              if (groupItems) {
                groupItems.push(vajraDistrict);
              } else {
                let newGroupItems = [vajraDistrict];
                groupNameDataMap[columnTypeName] = newGroupItems;
                this.groups.push({
                  groupName: columnTypeName,
                  groupItems: newGroupItems,
                });
              }
            }
          });
        }
        // this.groups = this.groups.reverse();
      }
    });
  }
}
